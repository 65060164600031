Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ObservableStore = void 0;
var observable_store_1 = require("./observable-store");
Object.defineProperty(exports, "ObservableStore", {
  enumerable: true,
  get: function () {
    return observable_store_1.ObservableStore;
  }
});
