var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ObservableStore = void 0;
var rxjs_1 = require("rxjs");
var observable_store_base_1 = require("./observable-store-base");
/**
 * Core functionality for ObservableStore
 * providing getState(), setState() and additional functionality
 */
var ObservableStore = /** @class */function () {
  function ObservableStore(settings) {
    this._stateDispatcher$ = new rxjs_1.BehaviorSubject(null);
    this._stateWithChangesDispatcher$ = new rxjs_1.BehaviorSubject(null);
    this._settings = __assign(__assign(__assign({}, observable_store_base_1.default.settingsDefaults), settings), observable_store_base_1.default.globalSettings);
    this.stateChanged = this._stateDispatcher$.asObservable();
    this.globalStateChanged = observable_store_base_1.default.globalStateDispatcher.asObservable();
    this.stateWithPropertyChanges = this._stateWithChangesDispatcher$.asObservable();
    this.globalStateWithPropertyChanges = observable_store_base_1.default.globalStateWithChangesDispatcher.asObservable();
    observable_store_base_1.default.services.push(this);
  }
  Object.defineProperty(ObservableStore.prototype, "stateHistory", {
    /**
     * Retrieve state history. Assumes trackStateHistory setting was set on the store.
     */
    get: function () {
      return observable_store_base_1.default.stateHistory;
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(ObservableStore, "globalSettings", {
    /**
     * get/set global settings throughout the application for ObservableStore.
     * See the [Observable Store Settings](https://github.com/danwahlin/observable-store#store-settings-per-service) documentation
     * for additional information. Note that global settings can only be set once as the application first loads.
     */
    get: function () {
      return observable_store_base_1.default.globalSettings;
    },
    set: function (settings) {
      // ObservableStore['isTesting'] used so that unit tests can set globalSettings 
      // multiple times during a suite of tests
      if (settings && (ObservableStore['isTesting'] || !observable_store_base_1.default.globalSettings)) {
        observable_store_base_1.default.globalSettings = settings;
      } else if (!settings) {
        throw new Error('Please provide the global settings you would like to apply to Observable Store');
      } else if (settings && observable_store_base_1.default.globalSettings) {
        throw new Error('Observable Store global settings may only be set once when the application first loads.');
      }
    },
    enumerable: false,
    configurable: true
  });
  Object.defineProperty(ObservableStore, "allStoreServices", {
    /**
     * Provides access to all services that interact with ObservableStore. Useful for extensions
     * that need to be able to access a specific service.
     */
    get: function () {
      return observable_store_base_1.default.services;
    },
    enumerable: false,
    configurable: true
  });
  /**
   * Used to add an extension into ObservableStore. The extension must implement the
   * `ObservableStoreExtension` interface.
   */
  ObservableStore.addExtension = function (extension) {
    observable_store_base_1.default.addExtension(extension);
  };
  /**
   * Used to initialize the store's starting state. An error will be thrown if this is called and store state already exists.
   * No notifications are sent out when the store state is initialized by calling initializeStoreState().
   */
  ObservableStore.initializeState = function (state) {
    observable_store_base_1.default.initializeState(state);
  };
  /**
   * Used to reset the state of the store to a desired value for all services that derive
   * from ObservableStore<T> to a desired value.
   * A state change notification and global state change notification is sent out to subscribers if the dispatchState parameter is true (the default value).
   */
  ObservableStore.resetState = function (state, dispatchState) {
    if (dispatchState === void 0) {
      dispatchState = true;
    }
    observable_store_base_1.default.setStoreState(state);
    if (dispatchState) {
      ObservableStore.dispatchToAllServices(state);
    }
  };
  /**
   * Clear/null the store state for all services that use it.
   */
  ObservableStore.clearState = function (dispatchState) {
    if (dispatchState === void 0) {
      dispatchState = true;
    }
    observable_store_base_1.default.clearStoreState();
    if (dispatchState) {
      ObservableStore.dispatchToAllServices(null);
    }
  };
  Object.defineProperty(ObservableStore, "isStoreInitialized", {
    /**
     * Determines if the ObservableStore has already been initialized through the use of ObservableStore.initializeState()
     */
    get: function () {
      return observable_store_base_1.default.isStoreInitialized;
    },
    enumerable: false,
    configurable: true
  });
  ObservableStore.dispatchToAllServices = function (state) {
    var services = ObservableStore.allStoreServices;
    if (services) {
      for (var _i = 0, services_1 = services; _i < services_1.length; _i++) {
        var service = services_1[_i];
        service.dispatchState(state);
      }
    }
  };
  /**
   * Retrieve store's state. If using TypeScript (optional) then the state type defined when the store
   * was created will be returned rather than `any`. The deepCloneReturnedState boolean parameter (default is true) can be used
   * to determine if the returned state will be deep cloned or not. If set to false, a reference to the store state will
   * be returned and it's up to the user to ensure the state isn't change from outside the store. Setting it to false can be
   * useful in cases where read-only cached data is stored and must be retrieved as quickly as possible without any cloning.
   */
  ObservableStore.prototype.getState = function (deepCloneReturnedState) {
    if (deepCloneReturnedState === void 0) {
      deepCloneReturnedState = true;
    }
    return this._getStateOrSlice(deepCloneReturnedState);
  };
  /**
   * Retrieve a specific property from the store's state which can be more efficient than getState()
   * since only the defined property value will be returned (and cloned) rather than the entire
   * store value. If using TypeScript (optional) then the generic property type used with the
   * function call will be the return type.
   */
  ObservableStore.prototype.getStateProperty = function (propertyName, deepCloneReturnedState) {
    if (deepCloneReturnedState === void 0) {
      deepCloneReturnedState = true;
    }
    return observable_store_base_1.default.getStoreState(propertyName, deepCloneReturnedState);
  };
  /**
   * Retrieve a specific property from the store's state which can be more efficient than getState()
   * since only the defined property value will be returned (and cloned) rather than the entire
   * store value. If using TypeScript (optional) then the generic property type used with the
   * function call will be the return type.
   * If a `stateSliceSelector` has been set, the specific slice will be searched first.
   */
  ObservableStore.prototype.getStateSliceProperty = function (propertyName, deepCloneReturnedState) {
    if (deepCloneReturnedState === void 0) {
      deepCloneReturnedState = true;
    }
    if (this._settings.stateSliceSelector) {
      var state = this._getStateOrSlice(deepCloneReturnedState);
      if (state.hasOwnProperty(propertyName)) {
        return state[propertyName];
      }
    }
    return null;
  };
  /**
   * Set store state. Pass the state to be updated as well as the action that is occuring.
   * The state value can be a function [(see example)](https://github.com/danwahlin/observable-store#store-api).
   * The latest store state is returned.
   * The dispatchState parameter can be set to false if you do not want to send state change notifications to subscribers.
   * The deepCloneReturnedState boolean parameter (default is true) can be used
   * to determine if the state will be deep cloned before it is added to the store. Setting it to false can be
   * useful in cases where read-only cached data is stored and must added to the store as quickly as possible without any cloning.
   */
  ObservableStore.prototype.setState = function (state, action, dispatchState, deepCloneState) {
    if (dispatchState === void 0) {
      dispatchState = true;
    }
    if (deepCloneState === void 0) {
      deepCloneState = true;
    }
    // Needed for tracking below (don't move or delete)
    var previousState = this.getState(deepCloneState);
    switch (typeof state) {
      case 'function':
        var newState = state(this.getState(deepCloneState));
        this._updateState(newState, deepCloneState);
        break;
      case 'object':
        this._updateState(state, deepCloneState);
        break;
      default:
        throw Error('Pass an object or a function for the state parameter when calling setState().');
    }
    if (this._settings.trackStateHistory) {
      observable_store_base_1.default.stateHistory.push({
        action: action,
        beginState: previousState,
        endState: this.getState(deepCloneState)
      });
    }
    if (dispatchState) {
      this.dispatchState(state);
    }
    if (this._settings.logStateChanges) {
      var caller = this.constructor ? '\r\nCaller: ' + this.constructor.name : '';
      console.log('%cSTATE CHANGED', 'font-weight: bold', '\r\nAction: ', action, caller, '\r\nState: ', state);
    }
    return this.getState(deepCloneState);
  };
  /**
   * Add a custom state value and action into the state history. Assumes `trackStateHistory` setting was set
   * on store or using the global settings.
   */
  ObservableStore.prototype.logStateAction = function (state, action) {
    if (this._settings.trackStateHistory) {
      observable_store_base_1.default.stateHistory.push({
        action: action,
        beginState: this.getState(),
        endState: observable_store_base_1.default.deepClone(state)
      });
    }
  };
  /**
   * 	Reset the store's state history to an empty array.
   */
  ObservableStore.prototype.resetStateHistory = function () {
    observable_store_base_1.default.stateHistory = [];
  };
  ObservableStore.prototype._updateState = function (state, deepCloneState) {
    observable_store_base_1.default.setStoreState(state, deepCloneState);
  };
  ObservableStore.prototype._getStateOrSlice = function (deepCloneReturnedState) {
    var storeState = observable_store_base_1.default.getStoreState(null, deepCloneReturnedState);
    if (this._settings.stateSliceSelector) {
      return this._settings.stateSliceSelector(storeState);
    }
    return storeState;
  };
  /**
   * Dispatch the store's state without modifying the store state. Service state can be dispatched as well as the global store state.
   * If `dispatchGlobalState` is false then global state will not be dispatched to subscribers (defaults to `true`).
   */
  ObservableStore.prototype.dispatchState = function (stateChanges, dispatchGlobalState) {
    if (dispatchGlobalState === void 0) {
      dispatchGlobalState = true;
    }
    // Get store state or slice of state
    var clonedStateOrSlice = this._getStateOrSlice(true);
    //  Get full store state
    var clonedGlobalState = observable_store_base_1.default.getStoreState();
    // includeStateChangesOnSubscribe is deprecated
    if (this._settings.includeStateChangesOnSubscribe) {
      console.warn('includeStateChangesOnSubscribe is deprecated. ' + 'Subscribe to stateChangedWithChanges or globalStateChangedWithChanges instead.');
      this._stateDispatcher$.next({
        state: clonedStateOrSlice,
        stateChanges: stateChanges
      });
      observable_store_base_1.default.globalStateDispatcher.next({
        state: clonedGlobalState,
        stateChanges: stateChanges
      });
    } else {
      this._stateDispatcher$.next(clonedStateOrSlice);
      this._stateWithChangesDispatcher$.next({
        state: clonedStateOrSlice,
        stateChanges: stateChanges
      });
      if (dispatchGlobalState) {
        observable_store_base_1.default.globalStateDispatcher.next(clonedGlobalState);
        observable_store_base_1.default.globalStateWithChangesDispatcher.next({
          state: clonedGlobalState,
          stateChanges: stateChanges
        });
      }
      ;
    }
  };
  return ObservableStore;
}();
exports.ObservableStore = ObservableStore;
