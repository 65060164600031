import { Injectable } from '@angular/core';

import { ObservableStore } from '@codewithdan/observable-store';
import { StoreAction } from './store-action';
import { StoreState } from './store-state';

import { AppuserModel } from '@app-shared/models/appuser.model';
import { LookupCollectionModel } from '@app-shared/models/lookupCollection.model';

import { environment } from '@app-environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StoreService extends ObservableStore<StoreState> {
  private initialState: StoreState = {
    lookupCollection: {
      appuser: [] as AppuserModel[]
    } as LookupCollectionModel,
    appuser: {} as AppuserModel
  };

  constructor() {
    super({
      trackStateHistory: environment.store.trackHistory,
      logStateChanges: environment.store.logChanges
    });

    this.initializeState();
  }

  initializeState(): void {
    this.setState(this.initialState, StoreAction.initializeState);
  }

  setAppuser(item: AppuserModel): void {
    this.setState({ appuser: item }, StoreAction.updateUser);
  }

  getAppuser(): AppuserModel {
    return this.getState().appuser;
  }

  getLookupCollections(): LookupCollectionModel {
    return this.getState().lookupCollection;
  }

  setLookupCollections(item: LookupCollectionModel): void {
    this.setState({ lookupCollection: item }, StoreAction.updateLookupCollection);
  }
}
